import {fetchBaseQuery, createApi} from '@reduxjs/toolkit/query/react';
import {getLocalStorageValue} from '../utils/localStorage';


const baseURL =  import.meta.env.MODE === 'production'
    ? import.meta.env.VITE_PRODUCTION_API_BASE_URL
    : import.meta.env.VITE_API_BASE_URL;


const tagTypes = [
    "projects",

    'UserWallet',
    'ConfiguredProject'

];

const baseQuery = createApi({
    tagTypes,

    baseQuery: fetchBaseQuery({
        baseUrl: baseURL,
        prepareHeaders: (headers) => {
            const token = getLocalStorageValue('token');
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),

    endpoints: () => ({}),
});

export const {injectEndpoints, endpoints} = baseQuery;

export default baseQuery;
